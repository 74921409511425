<template>
  <section class="conOfGrants h-100 mb-5">
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <Breadcrumb :BreadcrumbList="BreadcrumbList" />
          <HeaderPage :headerPage="{ part1: 'SSBD', part2: 'Grants' }" />
        </v-col>
      </v-row>
      <form class="conFormSubmit">
        <v-row>
          <v-col md="3" sm="6" cols="12">
            <div class="conDefaultInput">
              <v-text-field
                solo
                v-model="search.terms"
                label="Title Or Description"
                hide-details="auto"
              ></v-text-field>
            </div>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <div class="conSelectOption">
              <v-select
                outlined
                v-model="search.orderBy"
                :items="items"
                item-text="name"
                item-value="value"
                label="Order By"
                hide-details="auto"
                :menu-props="{ bottom: true, offsetY: true }" attach 
              ></v-select>
            </div>
          </v-col>


          <v-col md="3" sm="6" cols="12">
            <div class="confBtns">
              <v-btn
                class="srearchBnt primaryBtn mr-4"
                @click="onSearch"
                :disabled="isLoadingSearch"
                :loading="isLoadingSearch"
              >
                Search
              </v-btn>
              <v-btn class="clearBtn secondary-outline-btn" @click="onClear">
                clear
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </form>
    </v-container>

    <section class="mt-3" v-if="listItems && listItems.length > 0">
      <v-container>
        <v-row>
          <v-col cols="12" v-for="(item, index) in listItems" :key="index">
            <div class="conofcard">
              <v-card class="mx-auto mb-2" tile elevation="0">
                <v-container>
                  <v-row>
                    <v-col class="pa-0" md="5" cols="12">
                      <div class="conOfCardImg">
                        <v-img
                          height="200"
                          :src="item.image"
                          alt="item of the Image"
                        ></v-img>
                      </div>
                    </v-col>
                    <v-col class="pa-0" md="7" cols="12">
                      <div class="conOfAllData">
                        <div>
                          <v-card-title class="cardTitle">
                            <div class="titleSec">
                              {{ item.title }}
                            </div>
                          </v-card-title>
                          <v-card-title class="cardTitle">
                            <div class="subTitleSection">
                              {{ item.from }}
                            </div>
                          </v-card-title>
                          <v-card-text class="pb-0">
                            <div class="cardDesc">
                              {{ item.description }}
                            </div>
                          </v-card-text>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="pagination.total > pagination.per_page">
          <v-col cols="12">
            <div class="text-center">
              <v-pagination
                v-model="pagination.current_page"
                :length="pagination.last_page"
                @input="goToPage(pagination.current_page)"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <EmptyState v-else></EmptyState>
  </section>
</template>

<script>
import EmptyState from "@/modules/shared/components/emptystate";
import HeaderPage from "@/modules/shared/components/header-pages";
import Breadcrumb from "@/modules/shared/components/breadcrumbs-pages";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    dialog: false,
    name: "",
    menu: false,
    items: [
      {
        name: "newest",
        value: 1,
      },
      {
        name: "oldest",
        value: 2,
      },
    ],
    search: {
      terms: "",
      orderBy: null,
    },
    updatedFilter: null,
    pagination: {
      current_page: 1,
    },
    BreadcrumbList: [
      {
        text: "Research",
        disabled: true,
      },
      {
        text: "Grants",
        disabled: false,
      },
    ],
    listItems: null,
    isLoading: false,
    isLoadingSearch: false,
    updatedCurrent_page: false,
  }),
  components: {
    EmptyState,
    HeaderPage,
    Breadcrumb,
  },
  methods: {
    onClear() {
      // this.pagination.current_page = 1;
      this.search.terms = "";
      this.search.orderBy = null;
      this.pathByOaramSearch();
    },
    inzializeForm() {
      this.pagination.current_page = this.$route.query.current_page || 1;
      this.search.terms = this.$route.query.terms || "";
      this.search.orderBy = parseInt(this.$route.query.orderBy) || null;
    },
        pathByOaramSearch() {
      const query = Object.entries(this.search).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      if (
        JSON.stringify(this.updatedFilter) != JSON.stringify(query) ||
        this.updatedCurrent_page != this.pagination.current_page
      ) {
        this.$router.replace({
          name: "grants",
          query: { current_page: this.pagination.current_page, ...query },
        });
        this.updatedFilter = query;
        this.updatedCurrent_page = this.pagination.current_page;
      }
    },
    onSearch() {
      this.pagination.current_page = 1;
      this.pathByOaramSearch();
    },
    goToPage() {
      this.pathByOaramSearch();
      this.getListData();
      window.scrollTo(0, 0);
    },
    getListData() {
      this.listItems = [];
      this.isLoading = true;
      this.isLoadingSearch = true;

      apiServices
        .post("grants?page=" + this.pagination.current_page, this.search)
        .then((res) => {
          if (res) {
            this.listItems = res.data;
            this.pagination = res.meta;
            this.isLoading = false;
            this.isLoadingSearch = false;
          } else {
            this.isLoading = false;
            this.isLoadingSearch = false;
          }
        });
    },
  },
  watch: {
    "$route.params.query": {
      handler: function () {
        this.inzializeForm();
        this.getListData();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_grants.scss";
</style>
